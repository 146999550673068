<template>
  <div>
    <b-card>
      <b-card-title>Buscar tiquete</b-card-title>
      <b-card-sub-title>En esta sección puedes la busqueda de tu tiquete, solo debes ingresar los primero 4 números de documento, de esta forma podras visualizar los tiquetes y seleccionarlo</b-card-sub-title>
      <b-form class="mt-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <vue-autosuggest
                ref="autocomplete"
                v-model="query"
                :suggestions="suggestions"
                :input-props="inputProps"
                :section-configs="sectionConfigs"
                :render-suggestion="renderSuggestion"
                :get-suggestion-value="getSuggestionValue"
                @input="fetchResults"
              >
                <template slot="before-suggestions">
                  <h4
                    v-if="suggestions.length > 0"
                    class="pl-2 pt-2 pb-1"
                  >
                    Resultados de la busqueda: {{ suggestions[0].count }}
                  </h4>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card-text
      v-if="selected"
      class="mt-1"
    >
      Tiquete seleccionado
    </b-card-text>
    <b-card
      v-if="selected"
      no-body
      class="card-developer-meetup"
    >
      <b-card-body>
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <h6 class="mb-0">
              {{ setDay(selected.departure_date) }}
            </h6>
            <h3 class="mb-0">
              {{ setDate(selected.departure_date) }}
            </h3>
          </div>
          <div class="my-auto">
            <b-card-title class="mb-25">
              <b>Tiquete:</b> {{ selected.transaction[0]["id_transaction"] }}
            </b-card-title>
            <b-card-text class="mb-0">
              <b>Origen:</b> {{ selected.source }}
            </b-card-text>
            <b-card-text class="mb-0">
              <b>Destino:</b> {{ selected.destination }}
            </b-card-text>
            <b-card-text class="mb-0">
              <b>Fecha de salida (Aprox):</b> {{ selected.departure_date }}
            </b-card-text>
            <b-card-text class="mb-0">
              <b>Fecha de llegada (Aprox):</b> {{ selected.arrival_date }}
            </b-card-text>
            <b-card-text class="mb-0">
              <b>Sillas:</b> {{ getSillas(selected.chairs_select) }}
            </b-card-text>
          </div>
        </div>
        <hr>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          block
          variant="primary"
          @click="sendTicket"
        >
          Imprimir Tiquete
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import {
  BCol, BRow, BForm, BFormGroup, BCard, BCardText, BCardTitle, BCardBody, BButton, BCardSubTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueAutosuggest } from 'vue-autosuggest'
import TicketPurchaseHistory from '@/libs/models/TicketPurchaseHistory'
import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')

export default {
  name: 'FormSearch',
  components: {
    BCol,
    BRow,
    BForm,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BButton,
    BCardSubTitle,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      query: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 250,
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Ingresa los primero 4 digitos de tu documento',
        class: 'form-control',
        name: 'ajax',
      },
      suggestions: [],
      sectionConfigs: {
        ticket: {
          limit: 6,
          onSelected: selected => {
            this.selected = selected.item
          },
        },
      },
    }
  },
  methods: {
    capitalize(word) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase()
    },
    getSillas(sillas) {
      return sillas.map(item => item.label).toString()
    },
    fetchResults() {
      const { query } = this
      this.suggestions = []
      this.selected = null
      if (query.length >= 4) {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(async () => {
          const ticket = await TicketPurchaseHistory.where('document', query).get()
          this.suggestions.push({ name: 'ticket', data: ticket.data, count: ticket.data.length })
        }, this.debounceMilliseconds)
      }
    },
    setDate(value) {
      const data = moment(value, 'DD-MM-YYYY')
      return data.date()
    },
    setDay(value) {
      const data = moment(value, 'DD-MM-YYYY')
      return data.format('dddd').slice(0, 3).toUpperCase()
    },
    renderSuggestion(suggestion) {
      if (suggestion.name === 'ticket') {
        return (
          <b-card
            no-body
            class="card-developer-meetup mb-0"
          >
            <b-card-body class="p-1">
              <div class="meetup-header d-flex align-items-center">
                <div class="meetup-day">
                  <h6 class="mb-0">
                    {this.setDay(suggestion.item.departure_date)}
                  </h6>
                  <h3 class="mb-0">
                    {this.setDate(suggestion.item.departure_date)}
                  </h3>
                </div>
                <div class="my-auto">
                  <b-card-title class="mb-25">
                    <b>Tiquete:</b> {suggestion.item.transaction[0]?.id_transaction}
                  </b-card-title>
                  <b-card-text class="mb-0">
                    <b>Origen:</b> {suggestion.item.source}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Destino:</b> {suggestion.item.destination}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Fecha de salida (Aprox):</b> {suggestion.item.departure_date}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Fecha de llegada (Aprox):</b> {suggestion.item.arrival_date}
                  </b-card-text>
                  <b-card-text class="mb-0">
                    <b>Sillas:</b> { this.getSillas(suggestion.item.chairs_select) }
                  </b-card-text>
                </div>
              </div>
            </b-card-body>
          </b-card>
        )
      }
      return suggestion.item.document
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.transaction[0].id_transaction
    },
    sendTicket() {
      const a = document.createElement('a')
      a.target = '_blank'
      a.href = `${process.env.VUE_APP_FRONTEND_WEBSITE_URL_WITHOUT_HTTP}/payments/result/${this.selected.transaction[0].id_transaction}`
      a.click()
    },
  },
}
</script>
<style lang="scss" scope>
  .autosuggest__results-container .autosuggest__results {
    max-height: 55vh !important;
  }
  .autosuggest__results-container .autosuggest__results ul li {
    border-bottom: 1px solid gray;
  }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
